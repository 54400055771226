import { type ReactElement } from "react";
import clsx from "clsx";

import { Tag } from "../tag";
import styles from "./tag.module.css";

type RecTagProps = { active?: boolean; label?: string } & CommonProps;

function RecTag({
  active,
  label = "REC",
  className,
}: RecTagProps): ReactElement {
  return (
    <Tag className={clsx([styles.rec, className, active && styles.recorded])}>
      {label}
    </Tag>
  );
}

export { RecTag };
