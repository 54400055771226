import type { AssetType } from "@sunrise/backend-ng-recordings";
import type { PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType as SearchResultsType } from "@sunrise/backend-ng-search";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

export type NgSearchResult =
  | NgSearchEpgResult
  | NgSearchRecordingResult
  | NgSearchChannelResult;

export function mapBackendSearchResults(
  result: SearchResultsType["items"][0],
): Nullable<NgSearchResult> {
  if (result.type === "channel") {
    return {
      type: "channel",
      channelId: result.entry.id as ChannelId,
      channelName: result.entry.name,
      channelLogo: result.entry.logo,
    };
  }

  if (result.type === "epg_entry") {
    return {
      type: "epg",
      epgId: result.entry.id as EPGEntryId,
      epgStart: result.entry.start,
      epgEnd: result.entry.end,
      channelId: result.entry.channel.id as ChannelId,
      channelName: result.entry.channel.name,
      channelLogo: result.entry.channel.logo,
      coverImageUrl: result.entry.asset.poster_url,
      assetId: result.entry.asset.id as AssetId,
      title: result.entry.asset.title,
      forceLiveProgress: true,
      subtitle: result.entry.asset.subtitle,
      seasonNumber: result.entry.asset.season_number,
      episodeNumber: result.entry.asset.episode_number,
      assetType: result.entry.asset.type,
    };
  }

  if (result.type === "recording") {
    return {
      type: "recordingSingle",
      assetId: result.entry.asset.id as AssetId,
      channelId: result.entry.channel_id as ChannelId,
      channelLogo: result.entry.channel_logo,
      channelName: result.entry.channel_name,
      epg_end: new Date(result.entry.epg_end),
      title: result.entry.asset.title,
      posterUrl: result.entry.asset.poster_url,
      recordingStatus:
        result.entry.status === "recorded" ? "recorded" : "planned",
      epg_start: new Date(result.entry.epg_start),
      subtitle: result.entry.asset.subtitle ?? "",
      recordingId: result.entry.id as RecordingId,
    };
  }

  return null;
}

export type NgSearchEpgResult = {
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  title: Nullable<string>;
  subtitle?: Nullable<string>;
  seasonNumber?: Nullable<number>;
  episodeNumber?: Nullable<number>;
  epgStart: TimeISOString;
  epgEnd: TimeISOString;
  channelId: ChannelId;
  epgId: EPGEntryId;
  assetId: Nullable<AssetId>;
  forceLiveProgress?: boolean;
  assetType: Nullable<AssetType>;
  type: "epg";
};

export type NgSearchRecordingResult = {
  assetId: AssetId;
  channelId: ChannelId;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  epg_end: Date;
  title: string | null;
  type: "recordingSingle";
  posterUrl: string;
  recordingStatus: Nullable<SimplifiedRecordingStatus>;
  epg_start: Date;
  subtitle: string;
  recordingId: RecordingId;
};

export type NgSearchChannelResult = {
  channelId: ChannelId;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  type: "channel";
};
