import { Filter } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";

export const SearchFilters = () => {
  return (
    <Filter.Group className={styles.searchFilters} type="single">
      <Filter.Chip value="0">All results</Filter.Chip>
      <Filter.Chip value="1">Available</Filter.Chip>
      <Filter.Chip value="2">Upcoming</Filter.Chip>
      <Filter.Chip value="3">Recorded</Filter.Chip>
      <Filter.Chip value="4">Channels</Filter.Chip>
    </Filter.Group>
  );
};
