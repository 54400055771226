import type { InfiniteData } from "@tanstack/react-query";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import { ngContinueWatchingApiAtom } from "@sunrise/backend-ng-continue-watching";
import type { PageWithItems } from "@sunrise/backend-ng-core";
import type { HTTPValidationError } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import type { MappedContinueWatching } from "../types";
import { mapBackendContinueWatchingNg } from "./map-backend-continue-watching.ng";

export const continueWatchingPerUserNgAtom = atomWithInfiniteQuery<
  PageWithItems<MappedContinueWatching>,
  HTTPValidationError,
  InfiniteData<PageWithItems<MappedContinueWatching>>,
  ReturnType<typeof queryKeys.continueWatching>,
  number
>((get) => {
  const ngApi = get(ngContinueWatchingApiAtom);

  return {
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
        return undefined;

      const { page, pages } = lastPage;
      return page < pages ? page + 1 : undefined;
    },
    queryKey: queryKeys.continueWatching(get(selectJwtUserToken)),
    queryFn: async () => {
      const continueWatchingEntries =
        await ngApi.continueWatching.getContinueWatchingForUserContinueWatchingV1ContinueWatchingEntriesGet();
      return {
        ...continueWatchingEntries.data,
        items: continueWatchingEntries.data.items
          .map(mapBackendContinueWatchingNg)
          .filter((e): e is MappedContinueWatching => Boolean(e)),
      };
    },
  };
});
