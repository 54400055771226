import { addDays, endOfDay, startOfDay } from "date-fns";
import areEqual from "fast-deep-equal";
import type { ExtractAtomValue } from "jotai";
import { atom } from "jotai";
import { atomFamily, unwrap } from "jotai/utils";
import { uniqBy } from "lodash";

import type { ChannelId, TimeDay } from "@sunrise/backend-types-core";
import { dateToTimeDay, nowAtom } from "@sunrise/time";
import { selectEpgCollectionPerDayAtom } from "@sunrise/yallo-epg";

import { unwrappedAllChannelsInChannelGroup } from "./atoms/unwrapped-all-channels-in-channel-group.atom";
import type { GuideChannel, GuideProgram } from "./guide.types";
import { guideVisibleDataAtom } from "./guide-visible-data.atom";
import { channelToEmptyGuideChannel } from "./utils/channel-to-empty-guide-channel";
import { epgEntryToGuideProgram } from "./utils/epg-entry-to-guide-program";

/**
 * A pretty stable atom that just delivers a base skeleton of all the channels that should be loaded.
 */
const channelsForGuideAtom = atom((get) => {
  const channels = get(unwrappedAllChannelsInChannelGroup);

  if (!channels) {
    return null;
  }

  return channels.map(channelToEmptyGuideChannel);
});
const unwrappedChannelsForGuideAtom = unwrap(channelsForGuideAtom);

/**
 * The atom will attempt to load the guide data as needed.
 *
 * It'll take into account what data is visible and what data is not.
 * So it will only load data as needed.
 *
 * It will also take into account if a selection is made or not. So that data is loaded as well.
 *
 * It'll be a sync atom because we do not want to trigger suspense because of this.
 * When things load in the background it truly needs to load in the background.
 */
export const guideDataAtom = atom<{
  data: GuideChannel[];
  isLoadingInitial: boolean;
}>((get) => {
  const { channelIds, startTime, endTime } = get(
    gridDataThatShouldBeLoadedAtom,
  );
  const channels = get(unwrappedChannelsForGuideAtom);

  const data = (channels ?? []).map((channel) => {
    const needed = channelIds.includes(channel.id);

    if (!needed) {
      return channel;
    }

    // loop over all the days
    let current = startTime;
    const epgs: GuideProgram[] = [];
    do {
      const day = dateToTimeDay(current);

      // Grab all the EpgEntries for this day.
      epgs.push(
        ...get(
          unwrappedWindows({
            channelId: channel.id,
            day,
          }),
        ),
      );

      current = addDays(current, 1);
    } while (current < endTime);

    // If it is needed, we need to inject the correct data in it.
    return {
      ...channel,

      // NOTE: days can have overlapping epg entries, so all promises combined would contain some items twice.
      // This causes the guide to render the same item multiple times and thus breaking the navigation
      items: uniqBy(epgs, (program) => program.id),
    };
  });

  return {
    data,
    isLoadingInitial: !channels,
  };
});

/**
 * An atom to determine what should be loaded in the grid.
 */
const gridDataThatShouldBeLoadedAtom = atom<
  NonNullable<ExtractAtomValue<typeof guideVisibleDataAtom>>
>((get) => {
  const visible = get(guideVisibleDataAtom);

  if (visible) {
    return visible;
  }

  const now = get(nowAtom);

  return {
    channelIds: [],
    startTime: startOfDay(now),
    endTime: endOfDay(now),
  };
});

/**
 * This ensures we only map every item for every channel for every timeframe just a single time to a GuideProgram.
 */
const unwrappedWindows = atomFamily(
  (params: { channelId: ChannelId; day: TimeDay }) => {
    const atomForParams = unwrap(selectEpgCollectionPerDayAtom(params));

    return atom((get) => {
      try {
        const query = get(atomForParams);
        return (query?.data ?? []).map(epgEntryToGuideProgram);
      } catch {
        return [];
      }
    });
  },
  areEqual,
);
