import { CognitoService } from "@sunrise/auth";

import { webStore } from "./store";

const COGNITO_APP_CLIENT_NAME = import.meta.env.VITE_COGNITO_APP_CLIENT_NAME;

const createCognitoService = () => {
  if (!COGNITO_APP_CLIENT_NAME) return;
  return new CognitoService(webStore, COGNITO_APP_CLIENT_NAME);
};
export const login = (): void => {
  const cognito = createCognitoService();
  cognito?.loginWithRedirect();
};

export const logout = () => {
  const cognito = createCognitoService();
  cognito?.logout();
};
