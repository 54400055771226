import { atom } from "jotai";
import { atomFamily, loadable } from "jotai/utils";

import type { EPGEntryId } from "@sunrise/backend-types-core";

import { continueWatchingByEpgIdAtom } from "./continue-watching-by-epg-id.atom";
import { isFullyWatchedByEpgIdAtom } from "./is-fully-watched-by-epg-id.atom";

/**
 * Return relevant data related to continue watching for this epgId.
 */
export const continueWatchingDataByEpgIdAtom = atomFamily(
  (epgId: EPGEntryId) => {
    const innerAsync = atom(async (get) => {
      const fw = await get(isFullyWatchedByEpgIdAtom({ epgId }));
      if (fw) {
        return {
          isFullyWatched: true,
          continueWatchingItem: null,
        };
      }

      const item = await get(continueWatchingByEpgIdAtom({ epgId }));

      return {
        isFullyWatched: false,
        continueWatchingItem: item,
      };
    });

    const inner = atom((get) => {
      const load = get(loadable(innerAsync));

      return load.state === "hasData" ? load.data : null;
    });

    return inner;
  },
);
