import { selectAtomPerMinute } from "@sunrise/time";

import { playerCurrentDateTimeAtom } from "./player-current-date-time.atom";

export const selectPlayerCurrentDateTimePerMinute = selectAtomPerMinute(
  playerCurrentDateTimeAtom,
);

selectPlayerCurrentDateTimePerMinute.debugLabel =
  "selectPlayerCurrentDateTimePerMinute";
