import type { ReactNode } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

import { AirTime } from "../air-time";
import { Link } from "../button";
import { ChannelLogo } from "../logo";
import { ProgramTitleAndSubtitle } from "../program-title-and-subtitle";
import styles from "./program-box.module.css";
import { ProgramCover } from "./program-cover";

export type ProgramBoxProps = {
  variant: MappedDisplayType;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  accessibleSubtitle?: Nullable<string>;
  title: Nullable<string>;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  formattedStart: Nullable<string>; // formatted string 13:00
  formattedEnd: Nullable<string>; // formatted string 15:00
  airTime: Nullable<string>;
  subtitle?: Nullable<string>;
  active?: boolean;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  recordingState?: Nullable<SimplifiedRecordingStatus>;
  topProgramNumber?: number;
  recTagLabel?: string;
  isLive?: boolean;
  onPlay?: (() => void) | null;
  isDirectory?: boolean;
  redirectUrl?: Nullable<string>;
} & Pick<CommonProps, "className">;

function ProgramBox({
  title,
  startDate,
  endDate,
  className,
  formattedEnd,
  formattedStart,
  accessibleSubtitle,
  subtitle,
  channelLogo,
  channelName,
  coverImageUrl,
  variant,
  airTime,
  active,
  isDirectory,
  isLive,
  liveProgress,
  replayProgress,
  recordingState,
  topProgramNumber,
  recTagLabel,
  onPlay,
  redirectUrl,
}: ProgramBoxProps): ReactNode {
  const isHorizontal = variant === "horizontal";
  const size = isHorizontal ? "small" : "medium";
  const programBoxContent = (
    <>
      <span className={styles.airTime}>
        {isHorizontal && (
          <ChannelLogo
            className={styles.channelLogo}
            logo={channelLogo}
            name={channelName}
          />
        )}
        <AirTime
          endDate={endDate}
          formattedEnd={formattedEnd}
          formattedStart={formattedStart}
          isLive={isLive}
          relativeDate={airTime}
          size={size}
          startDate={startDate}
        />
      </span>
      <ProgramCover
        channelLogo={channelLogo}
        channelName={channelName}
        className={styles.programCover}
        coverImageUrl={coverImageUrl}
        isDirectory={isDirectory}
        liveProgress={liveProgress}
        recTagLabel={recTagLabel}
        recordingState={recordingState}
        replayProgress={replayProgress}
        title={title}
        topProgramNumber={topProgramNumber}
        variant={variant}
        onPlay={onPlay}
      />
      <ProgramTitleAndSubtitle
        accessibleSubtitle={accessibleSubtitle}
        active={active}
        className={styles.titles}
        redirectUrl={onPlay ? redirectUrl : undefined}
        size={size}
        subtitle={subtitle}
        title={title}
        variant={variant}
      />
    </>
  );

  if (redirectUrl && !onPlay) {
    return (
      <Link
        className={clsx([
          styles.container,
          variant && styles[variant],
          className,
        ])}
        href={redirectUrl}
        variant="none"
      >
        {programBoxContent}
      </Link>
    );
  }

  return (
    <div
      className={clsx(variant && styles[variant], styles.container, className)}
    >
      {programBoxContent}
    </div>
  );
}

export { ProgramBox };
