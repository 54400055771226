import type { MouseEvent, ReactNode } from "react";
import { useCallback } from "react";
import clsx from "clsx";

import { Icon } from "@sunrise/icons";
import { isDefined, type Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";

import { makeProgramBoxCoverImgUrl } from "../../utils/image";
import { Button } from "../button";
import buttonStyles from "../button/button.module.css";
import { ChannelLogo } from "../logo";
import { ProgressBar } from "../progress-bar";
import { RecTag } from "../tag";
import { Text } from "../text";
import styles from "./program-cover.module.css";

type ProgramCoverProps = {
  variant: MappedDisplayType;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  recordingState?: Nullable<"planned" | "recorded">;
  topProgramNumber?: number;
  recTagLabel?: string;
  onPlay?: (() => void) | null;
  isDirectory?: boolean;
  className?: string;
  title: Nullable<string>;
};

function ProgramCover({
  channelLogo,
  channelName,
  coverImageUrl,
  variant = "box",
  liveProgress,
  replayProgress,
  recordingState,
  topProgramNumber,
  recTagLabel,
  onPlay,
  isDirectory,
  className,
  title,
}: ProgramCoverProps): ReactNode {
  const play = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (onPlay) {
        e.preventDefault();
        onPlay();
      }
    },
    [onPlay],
  );

  if (!coverImageUrl) return null;

  const children = (
    <>
      <div
        className={clsx(styles.coverImage, {
          [styles.hasProgress]: isDefined(liveProgress),
        })}
      >
        <img
          alt=""
          decoding="async"
          loading="lazy"
          src={makeProgramBoxCoverImgUrl(
            coverImageUrl,
            variant === "horizontal" || !variant ? "box" : variant,
          )}
        />
      </div>

      <div className={styles.inner}>
        {variant !== "horizontal" &&
          (isDirectory ? (
            <Icon className={styles.directoryIcon} name="directory" />
          ) : (
            <ChannelLogo
              className={styles.channelLogo}
              logo={channelLogo}
              name={channelName}
            />
          ))}

        <ProgressBar
          className={styles.progress}
          liveProgress={liveProgress}
          replayProgress={replayProgress}
          variant={variant === "search" ? "search" : "programBox"}
        />
      </div>
      {recordingState && (
        <RecTag
          active={recordingState === "recorded"}
          className={styles.recordingTag}
          label={recTagLabel}
        />
      )}
      {topProgramNumber && (
        <Text className={styles.topProgramNumber} size="large">
          {topProgramNumber}
        </Text>
      )}
      {onPlay ? (
        <div
          className={clsx(
            variant !== "search" && [
              buttonStyles.button,
              buttonStyles.outlined,
              buttonStyles.iconOnly,
              buttonStyles.defaultRadius,
            ],
            styles.play,
          )}
        >
          <Icon name="play" />
        </div>
      ) : null}
    </>
  );

  if (!onPlay) {
    return (
      <div
        className={clsx([
          styles.container,
          variant && styles[variant],
          className,
        ])}
      >
        {children}
      </div>
    );
  }

  return (
    <Button
      aria-label={title ?? undefined}
      className={clsx([
        styles.container,
        styles.interactive,
        variant && styles[variant],
        className,
      ])}
      variant="none"
      onClick={play}
    >
      {children}
    </Button>
  );
}

export { ProgramCover };
