import type { NgRecording } from "@sunrise/backend-ng-recordings";
import {
  AssetType,
  RecordingGroupStatus,
  RecordingStatus,
} from "@sunrise/backend-ng-recordings";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

import type {
  GenericEpisodeRecordingNg,
  GenericRecording,
  GenericRecordingGroup,
  GenericSingleRecordingNg,
  SimplifiedRecordingStatus,
} from "../types";

export function ngRecordingToGenericRecording(
  recording: NgRecording,
): GenericRecording {
  if (recording.type === "recording") {
    const recordingStatus: SimplifiedRecordingStatus | null =
      recording.status === RecordingStatus.Planned
        ? "planned"
        : recording.status === RecordingStatus.Recorded
          ? "recorded"
          : null;

    const shared = {
      id: recording.id as RecordingId,
      channelLogo: recording.channel_logo ?? null,
      channelName: recording.channel_name ?? null,
      epg_end: new Date(recording.epg_end),
      epg_start: new Date(recording.epg_start),
      isPlayable: recording.status === RecordingStatus.Recorded,
      paddingEndMinutes: recording.padding_end_minutes,
      paddingStartMinutes: recording.padding_start_minutes,
      posterBlurHash: recording.asset.poster_blurhash ?? null,
      posterUrl: recording.asset.poster_url ?? null,
      subtitle: recording.asset.subtitle ?? null,
      title: recording.asset.title ?? null,
      channelId: (recording.channel_id as ChannelId) ?? null,
      epgEntryId: (recording.epg_entry_id as EPGEntryId) ?? null,
      assetId: recording.asset.id as AssetId,
      streamUrl: recording.stream_service_url ?? null,
      expiresAt: null,
      recordingStatus,
    };

    if (recording.asset.type === AssetType.Seriesepisode) {
      return {
        ...shared,
        type: "episode",
      } satisfies GenericEpisodeRecordingNg;
    }

    return {
      ...shared,
      type: "single",
    } satisfies GenericSingleRecordingNg;
  } else {
    return {
      id: recording.id as RecordingGroupId,
      type: "group",
      subtitle: recording.asset.subtitle ?? null,
      title: recording.asset.title ?? null,
      posterBlurHash: recording.asset.poster_blurhash ?? null,
      posterUrl: recording.asset.poster_url ?? null,
      seriesAssetId: recording.asset.id as AssetId,
      episodeCount: recording.episode_count ?? null,
      recordingStatus:
        recording.status === RecordingGroupStatus.AllPlanned
          ? "planned"
          : RecordingGroupStatus.AllRecorded
            ? "recorded"
            : null,
    } satisfies GenericRecordingGroup;
  }
}
