import type { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import type { Icons } from "@sunrise/icons";
import { Icon } from "@sunrise/icons";

import { Text } from "../text";
import styles from "./tag.module.css";

type TagProps = {
  title?: string;
  icon?: Icons;
} & PropsWithChildren &
  CommonProps;

function Tag({ children, className, icon, title }: TagProps): ReactElement {
  return (
    <div className={clsx(className, styles.tag)}>
      {!!icon && <Icon className={styles.icon} name={icon} />}
      <Text
        aria-label={title}
        className={styles.label}
        size="small"
        title={title}
        variant="label"
      >
        {children}
      </Text>
    </div>
  );
}

export { Tag };
