import { selectAtomPerMinute } from "@sunrise/time";

import { playerCurrentDateTimeShownAtom } from "./player-current-date-time-shown.atom";

export const selectPlayerCurrentDateTimeShownPerMinute = selectAtomPerMinute(
  playerCurrentDateTimeShownAtom,
);

selectPlayerCurrentDateTimeShownPerMinute.debugLabel =
  "selectPlayerCurrentDateTimeShownPerMinute";
