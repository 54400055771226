import type { ComponentProps, ReactElement } from "react";

import { BaseText } from "./base-text";
import type { HeadingElements } from "./types";

type Props = {
  level: HeadingElements;
} & Omit<ComponentProps<typeof BaseText>, "as">;

function Title({
  children,
  level,
  variant = "title",
  ...props
}: Props): ReactElement {
  return (
    <BaseText {...props} as={level} variant={variant}>
      {children}
    </BaseText>
  );
}

export { Title };
