import areEqual from "fast-deep-equal";
import { atom } from "jotai";

import type { ChannelId } from "@sunrise/backend-types-core";

type VisibleData = {
  channelIds: ChannelId[];
  startTime: Date;
  endTime: Date;
};

const internalGuideVisibleDataAtom = atom<VisibleData | null>(null);
internalGuideVisibleDataAtom.debugPrivate = true;

/**
 * Stores which data is now supposed to be visible in the guide.
 * Based on that, the guideDataAtom will load the necessary data.
 *
 * This will only ever change the internal data when the data differs from what is already in there.
 */
export const guideVisibleDataAtom = atom<
  VisibleData | null,
  [VisibleData],
  unknown
>(
  (get) => {
    return get(internalGuideVisibleDataAtom);
  },
  (get, set, visibleData) => {
    const current = get(internalGuideVisibleDataAtom);
    if (areEqual(current, visibleData)) {
      return;
    }

    set(internalGuideVisibleDataAtom, visibleData);
  },
);
