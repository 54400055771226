import { atomWithReducer } from "jotai/utils";

import type {
  ActionGuideLeaveGrid,
  ActionGuideResetOffset,
  ActionGuideSetOffset,
  CoordinatesState,
  GridStateAction,
  GuideGridState,
} from "./grid-state.types";

export const DEFAULT_COORDINATES_STATE: Readonly<CoordinatesState> = {
  x: -1,
  y: -1,
  handled: false,
};

export function makeGuideGridOffsetDefaultState(
  coordinates?: Partial<Readonly<CoordinatesState>>,
): Readonly<CoordinatesState> {
  return {
    ...DEFAULT_COORDINATES_STATE,
    ...coordinates,
  };
}

function gridOffsetAtomReducer(
  current: CoordinatesState,
  action: ActionGuideResetOffset | ActionGuideSetOffset | ActionGuideLeaveGrid,
): CoordinatesState {
  switch (action.type) {
    case "guide/leave-grid": {
      return {
        ...current,
        handled: false,
      };
    }
    case "guide/reset-offset": {
      return makeGuideGridOffsetDefaultState();
    }
    case "guide/set-offset": {
      return {
        x: action.payload.x,
        y: action.payload.y,
        handled: action.payload.handled,
      };
    }
  }
}

export const DEFAULT_GRID_STATE: Readonly<GuideGridState> = {
  coordinates: DEFAULT_COORDINATES_STATE,
  offsetPriority: "grid",
  selection: null,
  isFocused: false,
  jumpToDate: null,
};

export function makeDefaultGridState(
  state?: Partial<GuideGridState>,
): GuideGridState {
  return {
    ...DEFAULT_GRID_STATE,
    ...state,
  };
}

export function gridStateReducer(
  current: GuideGridState,
  action: GridStateAction,
): GuideGridState {
  switch (action.type) {
    case "guide/reset-offset": {
      return {
        ...current,
        jumpToDate: null,
        // Delegate the main logic for the coordinates to the coordinates reducer.
        coordinates: gridOffsetAtomReducer(current.coordinates, action),
        offsetPriority: "grid",
      };
    }
    case "guide/reset-selection": {
      return {
        ...current,
        selection: null,
        offsetPriority: "grid",
      };
    }
    case "guide/leave-grid": {
      return {
        ...current,
        coordinates: gridOffsetAtomReducer(current.coordinates, action),
      };
    }
    case "guide/set-offset": {
      return {
        ...current,
        // Delegate the main logic for the coordinates to the coordinates reducer.
        coordinates: gridOffsetAtomReducer(current.coordinates, action),
        offsetPriority: action.payload.offsetPriority,
      };
    }
    case "guide/set-selection": {
      return {
        ...current,
        jumpToDate: null,
        offsetPriority: "selection",
        selection: action.payload,
      };
    }
    case "guide/set-grid-focus": {
      return {
        ...current,
        isFocused: action.payload.isFocused,
      };
    }
    case "guide/jump-to-date": {
      return {
        ...current,
        jumpToDate: action.payload.date,
        offsetPriority: "select-date",
        selection: null,
      };
    }
    case "guide/reset": {
      return {
        ...DEFAULT_GRID_STATE,
      };
    }
  }
}

export const gridStateAtom = atomWithReducer(
  DEFAULT_GRID_STATE,
  gridStateReducer,
);
