import type { InfiniteData } from "@tanstack/query-core";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { HTTPValidationError } from "@sunrise/backend-ng-channel";
import type { PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType as SearchResults } from "@sunrise/backend-ng-search";
import { ngSearchApiAtom } from "@sunrise/backend-ng-search";
import { queryKeys } from "@sunrise/backend-types";

import { mapBackendSearchResults } from "./helpers/map-backend-search-result";

export type MappedNgSearchResult = Omit<SearchResults, "items"> & {
  items: ReturnType<typeof mapBackendSearchResults>[];
};
export const ngSearchByTermAtom = atomFamily((searchTerm: string) => {
  return atomWithInfiniteQuery<
    MappedNgSearchResult,
    HTTPValidationError,
    InfiniteData<MappedNgSearchResult>,
    ReturnType<typeof queryKeys.search>,
    number
  >((get) => {
    const api = get(ngSearchApiAtom);

    return {
      initialPageParam: 1,
      queryKey: queryKeys.search(searchTerm),
      queryFn: async ({ pageParam = 1 }) => {
        const { data } = await api.search.searchSearchV1SearchGet({
          query: searchTerm,
          page: pageParam,
          size: 20,
        });

        return {
          ...data,
          items: data.items.map(mapBackendSearchResults),
        };
      },
      getNextPageParam: (lastPage) => {
        if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
          return undefined;

        const { page, pages } = lastPage;
        return page < pages ? page + 1 : undefined;
      },
    };
  });
});
