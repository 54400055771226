import type { ReactNode } from "react";

import { useChannelDetails } from "@sunrise/yallo-channel";
import { useEpgRelativeTime } from "@sunrise/yallo-epg";
import { type GenericSingleRecording } from "@sunrise/yallo-recordings";
import { ProgramBox } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";
import { usePlayRecording } from "@/hooks/use-play-recording";

type RecordingItemSingleProps = Pick<
  GenericSingleRecording,
  | "id"
  | "posterUrl"
  | "epg_end"
  | "recordingStatus"
  | "epg_start"
  | "subtitle"
  | "title"
  | "channelId"
  | "assetId"
> & {
  // cover NG only values since we cant pick them from the GenericSingleRecording union
  channelLogo?: string | null;
  channelName?: string | null;
  forceLiveProgress?: boolean;
  replayProgress?: number | null;
};

export function RecordingItemSingle(
  props: RecordingItemSingleProps,
): ReactNode {
  const { formattedStart, formattedEnd, airTime, liveProgress } =
    useEpgRelativeTime({
      startDate: props.epg_start,
      endDate: props.epg_end,
      // TODO: wire up recording expiration
      expiresAt: null,
      forceLiveProgress: props.forceLiveProgress,
    });

  const { channelLogo, channelName } = useChannelDetails({
    channelId: props.channelId,
    name: "channelName" in props ? props.channelName : undefined,
    logo: "channelLogo" in props ? props.channelLogo : undefined,
  });

  const { play } = usePlayRecording({ recordingId: props.id });

  const url = route.recordings.single({
    assetId: props.assetId,
    recordingId: props.id,
  });

  return (
    <ProgramBox
      airTime={airTime}
      channelLogo={channelLogo}
      channelName={channelName}
      coverImageUrl={props.posterUrl}
      endDate={props.epg_end}
      formattedEnd={formattedEnd}
      formattedStart={formattedStart}
      liveProgress={liveProgress}
      recordingState={props.recordingStatus}
      redirectUrl={url}
      replayProgress={props.replayProgress}
      startDate={props.epg_start}
      subtitle={props.subtitle}
      title={props.title}
      variant="rowbox"
      onPlay={play}
    />
  );
}
