import { useCallback } from "react";
import { minutesToSeconds } from "date-fns";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type { RecordingId } from "@sunrise/backend-types-core";
import {
  actionPlayerManagerPlayRecording,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import {
  recordingByRecordingIdAtom,
  recordingStatusByRecordingIdAtom,
} from "@sunrise/yallo-recordings";

type UsePlayRecordingArgs = {
  recordingId: RecordingId;
};

/**
 * A hook to directly play out a recording.
 */
export const usePlayRecording = ({ recordingId }: UsePlayRecordingArgs) => {
  const play = useAtomCallback(
    useCallback(
      async (get, set) => {
        const recording = await get(recordingByRecordingIdAtom(recordingId));
        if (!recording || recording.type === "group") {
          return;
        }

        set(
          playerManagerAtom,
          actionPlayerManagerPlayRecording(
            recording.id,
            recording.channelId ?? null,
            recording.paddingStartMinutes
              ? minutesToSeconds(recording.paddingStartMinutes)
              : undefined,
          ),
        );
      },
      [recordingId],
    ),
  );

  const status = useAtomValue(recordingStatusByRecordingIdAtom(recordingId));
  const canPlay = status === "recorded";

  return { play, canPlay };
};
