import { useAtomValue } from "jotai";

import {
  debouncedQueryAtoms,
  searchResultsForTermPagedAtom,
} from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Spinner } from "@sunrise/yallo-web-components";

import { SearchPlaceholder } from "./search-placeholder";
import styles from "./search-results.module.css";
import { SearchChannelResult } from "./search-results/search-channel-result";
import { SearchEpgResult } from "./search-results/search-epg-result";

const map = {
  channel: SearchChannelResult,
  epg: SearchEpgResult,
  recordingSingle: null,
} as const;

type SearchResultsProps = {
  closeSearch?: () => void;
};

export const SearchResults = ({ closeSearch }: SearchResultsProps) => {
  const t = useTranslator();
  const searchTerm = useAtomValue(debouncedQueryAtoms.debouncedValueAtom);

  const { items, isLoadingInitialPage } = useAtomValue(
    searchResultsForTermPagedAtom(searchTerm),
  );

  if (isLoadingInitialPage)
    return <Spinner className={styles.spinner} variant="small" isActive />;

  if (!items || items.length === 0)
    return (
      <SearchPlaceholder
        key="empty"
        body={t("search_page_no_results_body")}
        title={t("search_page_no_results_title")}
      />
    );

  return (
    <ol className={styles.searchResultsList}>
      {items.map((item) => {
        if (!item) return;

        const SearchResult = map[item.type];
        if (!SearchResult) return null;

        if (item.type === "channel") {
          return (
            <li key={item.channelId} className={styles.result}>
              <SearchChannelResult closeSearch={closeSearch} item={item} />
            </li>
          );
        } else if (item.type === "epg") {
          return (
            <li key={item.epgId} className={styles.result}>
              <SearchEpgResult closeSearch={closeSearch} item={item} />
            </li>
          );
        }
      })}
    </ol>
  );
};
