import { useEffect, useState } from "react";
import { useSetAtom } from "jotai/react";

import { debouncedQueryAtoms } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import { SearchDialog } from "@/components/dialogs/search-dialog";

export function SearchButton() {
  const t = useTranslator();
  const [searchOpened, setSearchOpened] = useState(false);
  const setQuery = useSetAtom(debouncedQueryAtoms.currentValueAtom);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.altKey || event.metaKey) {
        return;
      }

      switch (event.key) {
        case "/": {
          if (searchOpened) return;
          setSearchOpened(true);
          break;
        }
      }
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [searchOpened]);

  return (
    <>
      <Button
        icon="search"
        variant="text"
        hideLabel
        onClick={() => setSearchOpened(true)}
      >
        {t("menu_search")}
      </Button>
      <SearchDialog
        open={searchOpened}
        onClose={() => {
          setSearchOpened(false);
          setQuery("");
        }}
      />
    </>
  );
}
