import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type {
  ContinueWatchingUpdate,
  FullyWatchedItem,
} from "@sunrise/backend-types";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { legacySocketAtom } from "@sunrise/yallo-websocket";

import { continueWatchingSocketUpdatesAtom } from "./continue-watching-socket-updates.atom";
import { fullyWatchedSocketUpdatesAtom } from "./fully-watched-socket-updates.atom";

export const reactForContinueWatchingToSocketEffect = atomEffect((get, set) => {
  if (!get(isLegacyBackendAtom)) {
    return;
  }

  const ws = get(legacySocketAtom);
  const isLoggedIn = get(selectIsLoggedIn);

  if (!ws || !isLoggedIn) {
    return;
  }

  ws.on("continue-watching", (event) => {
    set(continueWatchingSocketUpdatesAtom, event as ContinueWatchingUpdate);
  });

  ws.on("fully-watched", (event) => {
    set(fullyWatchedSocketUpdatesAtom, event as FullyWatchedItem);
  });

  return () => {
    // TODO: add a ws.off to the socket.
  };
});
