import { addDays, startOfDay, subDays } from "date-fns";
import { atom } from "jotai";

import { nowAtom } from "@sunrise/time";

import {
  GUIDE_WINDOW_DAYS_IN_FUTURE,
  GUIDE_WINDOW_DAYS_IN_PAST,
} from "./guide.constants";

/**
 * Returns the exact start and end time of the guide. Will be updated every minute.
 *
 * The window always starts at an absolute start of the day and ends at the absolute end of a day.
 */
export const guideWindowAtom = atom((get) => {
  const now = get(nowAtom);

  return {
    endTime: startOfDay(addDays(now, GUIDE_WINDOW_DAYS_IN_FUTURE + 1)),
    startTime: startOfDay(subDays(now, GUIDE_WINDOW_DAYS_IN_PAST)),
  };
});
