import { atom } from "jotai";

import { channelByIdAtom } from "@sunrise/yallo-channel-group";

import { EMPTY } from "./constants";
import { playerCurrentEpgItemAtom } from "./player-current-epg-item.atom";
import { selectPlayerCurrentPlayRequest } from "./selectors";
import type { PlayerCurrentContent } from "./types";

/**
 * It does an EPG lookup to know what is playing.
 * It returns the actual EPG item that is playing.
 * When we are seeking or in seek confirmation mode, it will still just return whatever the player is actually playing.
 */
export const playerCurrentContentEpgAtom = atom<Promise<PlayerCurrentContent>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);

    if (!playRequest || playRequest.type === "recording") {
      return EMPTY;
    }

    const [channel, epg] = await Promise.all([
      get(channelByIdAtom(playRequest.channelId)),
      get(playerCurrentEpgItemAtom),
    ]);

    if (!epg || !channel) {
      return EMPTY;
    }

    return {
      epgId: epg.id,
      assetId: epg.asset.id,
      channelId: channel.id,
      channelName: channel.name,
      recordingId: null,
      title: epg.asset.title,
      subtitle: epg.asset.subtitle,
      channelLogo: channel.logo,
      schedule: {
        endTime: new Date(epg.actualEnd),
        startTime: new Date(epg.actualStart),
      },
      image: epg.asset.posterPath,
    };
  },
);
