import type { ComponentProps, ReactElement } from "react";

import { BaseText } from "./base-text";
import { type TextElements } from "./types";

type Props = {
  as?: TextElements;
} & Omit<ComponentProps<typeof BaseText>, "as">;

function Text({ children, as = "span", ...props }: Props): ReactElement {
  return (
    <BaseText as={as} {...props}>
      {children}
    </BaseText>
  );
}

export { Text };
