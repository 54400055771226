import { useAtomValue } from "jotai/react";

import { selectSearchEnabled } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Button, Dialog } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";
import { SearchFilters } from "./search-filters";
import { SearchHistory } from "./search-history";
import { SearchHistoryHeader } from "./search-history-header";
import { SearchInput } from "./search-input";
import { SearchResults } from "./search-results";

export const SearchDialog = ({
  onClose,
  open,
}: {
  onClose: (() => void) | undefined;
  open: boolean;
}) => {
  const t = useTranslator();
  const isSearching = useAtomValue(selectSearchEnabled);

  return (
    <Dialog
      className={styles.searchDialog}
      open={open}
      closable
      onClose={onClose}
    >
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.inputWrapper}>
            <Button icon="arrowLeft" variant="text" hideLabel onClick={onClose}>
              {t("button_go_back")}
            </Button>
            <SearchInput />
          </div>
          {isSearching ? <SearchFilters /> : <SearchHistoryHeader />}
        </header>
        {isSearching ? (
          <SearchResults closeSearch={onClose} />
        ) : (
          <SearchHistory />
        )}
      </div>
    </Dialog>
  );
};
