import { atomFamily } from "jotai/utils";

import { pagedAtom } from "@sunrise/backend-ng-core";

import { ngSearchByTermAtom } from "./ng-search.atom";

/**
 * Search results for a term (epg, channels, recordings)
 *
 */
export const searchResultsForTermPagedAtom = atomFamily((term: string) =>
  pagedAtom(ngSearchByTermAtom(term)),
);
