import { atom } from "jotai";
import uniqWith from "lodash/uniqWith";

import { fullyWatchedSocketUpdatesAtom } from "../socket/fully-watched-socket-updates.atom";
import { fullyWatchedQueryAtom } from "./fully-watched-query.atom";

export const fullyWatchedItemsAtom = atom(async (get) => {
  const { data } = await get(fullyWatchedQueryAtom);
  const socket = get(fullyWatchedSocketUpdatesAtom);

  return uniqWith(
    [...data, ...socket],
    (a, b) => a.epg_entry.id === b.epg_entry.id,
  );
});
