import type { ReactElement } from "react";
import { forwardRef } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";

import { Link } from "../button";
import type { HeadingElements } from "../text";
import { Text, Title } from "../text";
import styles from "./program-title-and-subtitle.module.css";

type ProgramTitleAndSubtitleProps = {
  size: "small" | "medium" | "large";
  variant?: MappedDisplayType;
  title: Nullable<string>;
  level?: HeadingElements;
  /**
   * A written out version of S01 E02: <title> to Season 1 Episode 2: <title> for accessibility tools
   * See {@link getPrefixAndSubtitle}
   */
  accessibleSubtitle?: Nullable<string>;
  subtitle?: Nullable<string>;
  active?: boolean;
  redirectUrl?: Nullable<string>;
  id?: string;
  underline?: boolean;
} & CommonProps;

const ProgramTitleAndSubtitle = forwardRef<
  HTMLDivElement,
  ProgramTitleAndSubtitleProps
>(function ProgramTitleAndSubtitle(
  {
    id,
    size,
    variant,
    title,
    accessibleSubtitle,
    underline = false,
    subtitle,
    className,
    active,
    redirectUrl,
    level = "h3",
  },
  ref,
): ReactElement {
  const isHorizontal = variant === "horizontal";
  const titleElement = (
    <Title
      className={clsx([
        styles.wrapText,
        styles.title,
        active && styles.activeTitle,
      ])}
      id={id}
      level={level}
      size={size}
      underline={underline}
      variant="title"
    >
      {title}
    </Title>
  );

  return (
    <div
      ref={ref}
      className={clsx([
        styles.container,
        className,
        variant && styles[variant],
      ])}
    >
      {!!title &&
        (redirectUrl ? (
          <Link className={styles.titleLink} href={redirectUrl} variant="none">
            {titleElement}
          </Link>
        ) : (
          titleElement
        ))}
      {!!subtitle && (
        <Text
          aria-label={accessibleSubtitle ?? undefined}
          as="p"
          className={clsx([styles.wrapText, styles.subtitle])}
          size={isHorizontal ? "small" : "large"}
          variant="label"
        >
          {subtitle}
        </Text>
      )}
    </div>
  );
});

export { ProgramTitleAndSubtitle };
